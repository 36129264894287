import Api from './Api.js';

const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getPriority(filters, sort) {
    let url = `/v2/master/getPriorityData?organization_id=${organization_id()}`
    if (filters.hasOwnProperty("search")) {
      url += `&search=${filters.search}`;
    }
    return Api().get(url);

  },
  addPriority(payload) {
    return Api().post(`/v2/master/addPriorityData?organization_id=${organization_id()}`, payload);
  },
  updatePriority(payload, priority_id) {
    return Api().put(`/v2/master/updatePriorityData/${priority_id}?organization_id=${organization_id()}`, payload);
  },

   getPaymentMode(filters, sort) {
       let url = `/v2/master/getPaymentModeData?organization_id=${organization_id()}`
       if (filters.hasOwnProperty("search")) {
         url += `&search=${filters.search}`;
       }
       return Api().get(url);

     },
     addPaymentMode(payload) {
       return Api().post(`/v2/master/addPaymentModeData?organization_id=${organization_id()}`, payload);
     },
     updatePaymentMode(payload, priority_id) {
       return Api().put(`/v2/master/updatePaymentModeData/${priority_id}?organization_id=${organization_id()}`, payload);
  },
        getGST(filters, sort) {
            let url = `/v2/master/getGSTData?organization_id=${organization_id()}`
            if (filters.hasOwnProperty("search")) {
              url += `&search=${filters.search}`;
            }
            return Api().get(url);

          },
          addGST(payload) {
            return Api().post(`/v2/master/addGSTData?organization_id=${organization_id()}`, payload);
          },
          updateGST(payload, priority_id) {
            return Api().put(`/v2/master/updateGSTData/${priority_id}?organization_id=${organization_id()}`, payload);
          },

}
